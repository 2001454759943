<template>
  <div class="row my-2">
    <div class="col-3">
      <label class="text-color">HCP Types</label>
      <input type="text" id="name" class="form-control" />
    </div>
    <div class="col-3">
      <label class="text-color">Start Date</label>
      <input type="date" id="date" class="form-control" />
    </div>
    <div class="col-3">
      <label class="text-color">End Date</label>
      <input type="date" id="date" class="form-control" />
    </div>
    <div class="col-3">
      <label class="text-color">Active?</label>
      <input class="form-check-input check-box" type="checkbox" id="active" />
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-3">
      <input type="text" id="name" class="form-control" />
    </div>
    <div class="col-3">
      <input type="date" id="date" class="form-control" />
    </div>
    <div class="col-3">
      <input type="date" id="date" class="form-control" />
    </div>
    <div class="col-3">
      <input type="checkbox" id="active" class="form-check-input check-box" />
    </div>
  </div>
  <div class="row mt-3" v-for="hcpTypes in hcp_Types" :key="hcpTypes.index">
    <div class="col-3">
      <input
        type="text"
        v-model="hcpTypes.one"
        id="name"
        class="form-control"
      />
    </div>
    <div class="col-3">
      <input
        type="date"
        v-model="hcpTypes.two"
        id="date"
        class="form-control"
      />
    </div>
    <div class="col-3">
      <input
        type="date"
        v-model="hcpTypes.three"
        id="date"
        class="form-control"
      />
    </div>
    <div class="col-3">
      <input
        type="checkbox"
        v-model="hcpTypes.four"
        id="active"
        class="form-check-input check-box"
      />
    </div>
  </div>
  <div class="row">
    <!-- <div class="col-10 mt-3">
      <hr class="hr-border" />
    </div> -->
    <div class="col-12">
      <button
        type="button"
        @click="hcpTypesForm()"
        id="btn_schedule_add"
        class="btn btn_add plus"
      >
        <font-awesome-icon :icon="['fas', 'plus-circle']" />
      </button>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-7" align="right">
      <button type="button" class="btn btn-blue-color text-white">Save</button>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  data() {
    return {
      hcp_Types: [
        {
          one: "",
          two: "",
          three: "",
          four: "",
        },
      ],
    };
  },
  methods: {
    hcpTypesForm() {
      this.hcp_Types.push({
        one: "",
        two: "",
        three: "",
        four: "",
      });
    },
  },
};
</script>
<style>
.btn.btn_add.plus {
  float: right;
  font-size: 30px;
}
.form-check-input.check-box[type="checkbox"] {
  margin: 10px;
}
</style>