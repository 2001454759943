<template>
  <div class="page-content w-100">
    <!-- start heading -->
    <div class="row">
      <div class="col-sm-12">
        <p class="text-center page-heading">Assets Manager</p>
      </div>
    </div>
    <!-- end heading -->
    <div class="row mt-3" style="width:auto">
      <div class="col-sm-12 mx-auto div-fix">
        <!-- start accordion -->
        <div class="accordion box-shadow" id="assetAccordion" style="width:850px;">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button class="accordion-button collapsed text-color border-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePracticeOne" aria-expanded="true" aria-controls="collapsePracticeOne">
                <strong>Practice Areas and Resources</strong>
              </button>
            </h2>
            <div id="collapsePracticeOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#assetAccordion">
              <div class="accordion-body p-4">
                  <practiceArea></practiceArea>
              </div>
            </div>
          </div>
          <!-- end first -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button class="accordion-button collapsed text-color border-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePracticeTwo" aria-expanded="false" aria-controls="collapsePracticeTwo">
                <strong>Patient support groups</strong>
              </button>
            </h2>
            <div id="collapsePracticeTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#assetAccordion">
              <div class="accordion-body p-4">
                  <patientSupport></patientSupport>
              </div>
            </div>
          </div>
          <!-- end second -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button class="accordion-button collapsed text-color border-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePracticeThree" aria-expanded="true" aria-controls="collapsePracticeThree">
                <strong>HCP Types</strong>
              </button>
            </h2>
            <div id="collapsePracticeThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#assetAccordion">
              <div class="accordion-body p-4">
                  <hcp-types></hcp-types>
              </div>
            </div>
          </div>
          <!-- end third -->
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button class="accordion-button collapsed text-color border-color" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePracticeFour" aria-expanded="true" aria-controls="collapsePracticeFour">
                <strong>Resource Links</strong>
              </button>
            </h2>
            <div id="collapsePracticeFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#assetAccordion">
              <div class="accordion-body p-4">
                  <resource-links></resource-links>
              </div>
            </div>
          </div>
          <!-- end third -->
        </div>

        <!-- end accordion -->
      </div>
    </div>
  </div>
</template>

<script>
import patientSupport from "./patientSupport.vue"
import practiceArea from "./practiceArea.vue"
import hcpTypes from "./hcpTypes.vue"
import resourceLinks from "./resourceLinks.vue"
export default {
   components:{
     patientSupport,
     practiceArea,
     hcpTypes,
     resourceLinks
   }
}
</script>
<style>
  .col-sm-12.mx-auto.div-fix{
    margin-bottom:30px;
  }
  .page-heading {
    color: #00979e;
    font-size: 28px;
    font-weight: bold;
    text-align: left !important;
    padding: 8px;
    border-bottom: 2px solid #51cfd4;
    width: 850px;
}
</style>