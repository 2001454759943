<template>
  <div>
    <div class="row my-2">
      <div class="col-4">
        <label class="text-color">Group name</label>
      </div>
      <!-- <div class="col-3">
      <label class="text-color">Speciality</label>
    </div> -->
      <div class="col-3">
        <label class="text-color">#doctors</label>
      </div>
      <div class="col-3">
        <label class="text-color">#users</label>
      </div>
      <div class='col-2'>
        <label class="text-color">A/D</label>
      </div>
    </div>
    <div class="row mt-2" v-for="(value, index) in updateValue" :key="index">
      <div class="col-4">
        <input type="text" :disabled="!value.isEditable" :value="value.grouplongname" id="name" name="name"
          autocomplete="off" class="form-control" />
      </div>
      <!-- <div class="col-3">
      <input type="text" :disabled="!value.isEditable" :value="value.specialities"  id="name" name="name"
        autocomplete="off" class="form-control" />
    </div> -->
      <div class="col-3">
        <input type="text" :disabled="!value.isEditable" :value="value.doctorCount" id="name" name="name" autocomplete="off" class="form-control" />
      </div>
      <div class="col-3">
        <input type="text" :disabled="!value.isEditable" :value="value.publicCount" id="name" name="name" autocomplete="off" class="form-control" />
      </div>
      <div class="col-1">
        <input type="checkbox" v-model="value.status"  name="checkbox"
          :disabled="!value.isEditable" />
      </div>
      <div class="col-1">
        <button type="button" @click="handleInputEditState(value, index)" style="font-size: 20px" id="btn_schedule_add"
          class="btn btn_edit">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-10 mt-3">
        <hr class="hr-border" />
      </div>
      <div class="col-2">
        <button type="button" @click="createPatientGroup" style="font-size: 30px" id="btn_schedule_add"
          class="btn btn_add">
          <font-awesome-icon :icon="['fas', 'plus-circle']" />
        </button>
      </div>
    </div>
    <div v-if="patientSupport == true">
      <div class="border-color p-4">
        <h5 class="text-color taskmanager-title1">About the group</h5>
        <div class="row">
          <div class="col-6">
            <label for="exampleFormControlInput1" class="form-label">Group long name</label>
            <input class="form-control" type="text" v-model="grouplongname" aria-label="group_name" />
          </div>
          <div class="col-6">
            <label for="exampleFormControlInput1" class="form-label">Short name</label>
            <input class="form-control" type="text" v-model="shortname" aria-label="short_name" />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-8">
            <label class="text-color">Disorder</label>
            <VueMultiselect v-model="specialities" :options="option" :multiple="true" :close-on-select="true"
              placeholder="Pick some" label="name" track-by="name" />
          </div>
          <div class="col-4 mt-4">
            <button class="btn btn-blue-color text-white" @click="editbutton ? patientUpdateGroup() : patientSubGroup()"
              style="width: -webkit-fill-available;">{{ this.editbutton ? "Update" : "Save" }}</button>
          </div>
        </div>
      </div>
      <div class="border-color p-4">
        <h5 class="text-color taskmanager-title2">Topics of the group</h5>
        <div class="row mt-3">
          <div class="col-6">
            <select class="form-control" id="inputGroupSelect01" v-model="topicGroup" @click="groupSelect(topicGroup)">
              <option value="1">Select</option>
              <option v-for="(value, index ) in special" :key="index" :value="value">{{
                value.name
              }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <label class="text-color">Practice area topics</label>
            <div id="ctext" name="ctext" class="form-control checkselect" rows="6" style="width:314px">
              <button :class="value.isSelected ? 'btn btn-blue-color selected tooltip' : 'btn btn-outline-secondary selected tooltip'"
                v-for="(value, index ) in valueData" :key="index" :value="value" @click="selectButton(index, value)">
                <div class="practice-topic" :title="value.topic">
                  {{ value.topic }}
                </div>
                <span class="tooltiptext">{{ value.topic }}</span>
              </button>
            </div>
          </div>
          <div class="col mt-5">
            <button class="arrow-button" @click="leftToAll()">>></button>
            <button class="arrow-button" @click="leftToRight()">></button>
            <button class="arrow-button" @click="rightToAll()">{{ this.rightArrow }}</button>
            <button class="arrow-button" @click="rightToLeft()">{{ this.rA }}</button>
          </div>
          <div class="col">
            <label class="text-color">Topics of groups</label>
            <div id="ctext" name="ctext" class="form-control checkselect" rows="6" style="width:314px">
              <button :class="value.isSelected ? 'btn btn-outline-secondary selected tooltip' : 'btn btn-blue-color selected tooltip'"
                v-for="(value, index ) in selectTopics" :key="index" :value="value" @click="moveButton(index, value)">
                <div class="practice-topic-move" :title="value.topic">
                  {{ value.topic }}
                </div>
                <span class="tooltiptext">{{ value.topic }}</span>
                </button>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-4"></div>
          <div class="col-4">
            <button class="btn btn-blue-color text-white" @click="editbutton ? updateTopics() : saveTopics()" style="margin-left:65px;">
              {{ this.editbutton ? "Update topics" : "Add topics" }}</button>
          </div>
          <div class="col-3"></div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-8">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="active" id="active" v-model="status" />
            <label class="form-check-label text-color" for="activateORdeactivate">Activate / Deactivate</label>
          </div>
        </div>
        <div class="col-4">
          <button type="button" @click="editbutton ? updateSavingGroup() : savingGroup()" class="btn btn-blue-color text-white"
            style="width: -webkit-fill-available;">{{ this.editbutton ? "Update" : "Save" }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueMultiselect from "vue-multiselect";
// import moment from 'moment';
import axios from 'axios'
export default {
  components: { VueMultiselect },
  data() {
    return {
      patientSupport: false,
      groupUpdate: [],
      option: [],
      topics: [],
      special: [],
      valueData: [],
      practiceAreaTopics2: [],
      selectTopics: [],
      specialities: [],
      grouplongname: "",
      shortname: "",
      groupname: "",
      practicetopics: [],
      topicsdata: [],
      practiceareatopics: [],
      patientSupData: [],
      patientAboutData: [],
      name: [],
      rightArrow: '<<',
      rA: '<',
      topicGroup: null,
      userPayload: [],
      userPayloadUpdate: [],
      groupData: {},
      groupDatas: {},
      subGroupValue: [],
      updateValue: [],
      specialitiesValues: [],
      groupDataValue: null,
      status: false,
      getValueGroup: [],
      valueArray: [],
      addData: [],
      addPatient: [],
      getData:[],
      userData:[],
      editbutton:false,
      updateValueId:[],
      patientUpdateData:{},
      doctorDisCount:[]
    }
  },
  created: async function () {
    this.getAllHcps();
    this.getAllUsers();
    this.getHcps();
    this.getUsers();
    this.getTopics();
    await this.getPatientSubGroup();
    await this.getPatientAboutGroup();
    this.getGroup();
  },
  mounted(){
    this.topicGroup = "1"
  },
  methods: {
    getAllHcps() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
axios
  .get(`${BASE_API_URL}/hcps`)
  .then((response) => {
    if (response?.data) {
      const doctor = response.data.flatMap((data) => data.doctorPatientId || []);
      const doctorCounts = {};
      doctor.forEach((doc) => {
        const groupName = doc.group_name;
        if (groupName) {
          if (!doctorCounts[groupName]) {
            doctorCounts[groupName] = 1;
          } else {
            doctorCounts[groupName]++;
          }
        }
      });
      this.updateValue.forEach((value) => {
        if(value.status == true){
          const count = doctorCounts[value.grouplongname] || 0;
          value.doctorCount = count;
        }else{
          value.doctorCount = 0;
        }
        });
    }
  })
  .catch(function (error) {
    return error;
  });
},
getAllUsers() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
axios
  .get(`${BASE_API_URL}/public-user`)
  .then((response) => {
    if (response?.data) {
      const doctor = response.data.flatMap((data) => data.publicPatientId || []);
      const publicCounts = {};
      doctor.forEach((doc) => {
        const groupName = doc.group_name;
        if (groupName) {
          if (!publicCounts[groupName]) {
            publicCounts[groupName] = 1;
          } else {
            publicCounts[groupName]++;
          }
        }
      });
      this.updateValue.forEach((value) => {
        if(value.status == true){
          const count = publicCounts[value.grouplongname] || 0;
          value.publicCount = count;
        }else{
          value.publicCount = 0;
        }
        });
    }
  })
  .catch(function (error) {
    return error;
  });
},
    createPatientGroup(){
      this.patientSupport=true
      this.grouplongname=''
      this.shortname = '';
      this.specialities = '';
      this.status = false;
      this.valueData=[];
      this.special=[];
      this.topicGroup='1';

    },
    async getTopics() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      await axios
        .get(`${BASE_API_URL}/topics`)
        .then((response) => {
          if (response?.data) {
            this.option = response.data;
          }
        })
        .catch(function (error) {
          return error
        });
    },
    getHcps() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let DoctorUserId = localStorage.getItem('id')
      this.DoctorId = JSON.parse(DoctorUserId)
      axios
        .get(`${BASE_API_URL}/hcps/${DoctorUserId}/one`)
        .then((response) => {
          if(response?.data){
            this.getData = response.data
          }
        })
        .catch(function (error) {
          return error
        });
    },
    getUsers() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let UserId = localStorage.getItem('publicSignUpData')
      this.usersId = JSON.parse(UserId)
      axios
        .get(`${BASE_API_URL}/public-user/${UserId}/one`)
        .then((response) => {
          if(response?.data){
            this.userData = response.data
          }
        })
        .catch(function (error) {
          return error
        });
    },
    async patientSubGroup() {
      this.hcpId = localStorage.getItem('id')
      this.patientSupData?.map((patientData) => {
        if (patientData.groupname === patientData.groupname) {
          this.groupData = patientData
        }
      })
      await this.getPatientSupportById(this.grouplongname)
      let selectedData = [];
      let wait = this.special.map((value) => {
        value?.topics?.map(topic => {
          selectedData = [...selectedData, topic];
        });
      });
      await Promise.all(wait);
      let payload = {
        grouplongname: this.grouplongname,
        shortname: this.shortname,
        specialities: this.specialities,
        status: this.status,
        hcpPatient: this.getData,
        hcps_id: this.getData,
        users_id: this.userData
      }
      if (this.specialities.length == this.specialitiesValues.length || this.specialitiesValues.length == 0 || !this.groupDataValue.id) {
        this.patientsupAdd(payload)
      }
      else {
        this.patientUpdateGroup(payload, this.groupDataValue)
      }
    },
    async patientsupAdd(payload) {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .post(`${BASE_API_URL}/patient-support/add`, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
            this.getValueGroup = response.data;
            this.patientIdValue = localStorage.setItem("patient-id",response.data.data.id)
            this.valueArray = this.getValueGroup.data;
            this.groupname = response.data.data.grouplongname;
            this.shortname = response.data.data.shortname;
            this.specialities = response.data.data.specialities;
            this.status = response.data.data.status;
            this.special = this.specialities
            this.topicGroup = response.data.data.specialities;
            this.hcps_id = response.data.data.hcps_id;
            this.hcpPatient = response.data.data.hcpPatient;
            this.users_id = response.data.data.users_id;
            this.getPatientAboutGroup();
            this.$swal("Added")
            this.addData.push(localStorage.getItem('GroupArray'));
            this.addData.push(JSON.stringify(this.valueArray));
            localStorage.setItem('GroupArray', this.addData)
          }
        })
        .catch(function (error) {
          return error
        })
    },
    
    async patientUpdateGroup() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let updatesGroupPayload = {
        groupname: this.grouplongname,
        shortname: this.shortname,
        specialities: this.specialities,
        status: this.status,
        hcpPatient: this.getData,
        hcps_id: this.getData,
        users_id: this.userData
      }
      await axios
        .put(`${BASE_API_URL}/patient-support/add/${this.updateValueId}/update`, updatesGroupPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
      this.topicGroup='1';
            this.userPayloadUpdate = response.data;
            this.groupSelect();
            this.getPatientAboutGroup();
            this.$swal("Updated")
          }
        })
        .catch(function (error) {
          return error
        });
    },

    async groupSelect(topicGroup) {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      this.special = this.specialities
      await this.getPatientSupportById(this.grouplongname)
      this.selectTopics = [];
      if (this.subGroupValue) {
        this.subGroupValue?.map((data) => {
          if (topicGroup.name === data.practicetopics) {
            this.valueData = [];
            this.selectTopics = [];
            data.practiceareatopics = data.practiceareatopics.filter(item => !data.topicsdata.includes(item));
            this.valueData = data.practiceareatopics
            this.selectTopics = data.topicsdata
          }
          else {
            if (!this.subGroupValue) {
              this.valueData = topicGroup.topics;
            }
          }
        })
        if (!this.subGroupValue) {
          this.valueData = topicGroup.topics;
        }
        this.valueData = topicGroup.topics;
      }
      let disorderId = JSON.parse(localStorage.getItem('disorder'))
      let letter;
      if (disorderId) {
        this.url = `${BASE_API_URL}/topic-disorders?q=${letter}&disorderId=${topicGroup.name}`
      } else {
        this.url = `${BASE_API_URL}/topic-disorders?q=${letter}&disorderId=${topicGroup.name}`
      }
      axios
        .get(this.url)
        .then(async (response) => {
          this.valueData = response.data;
        })
    },
    selectButton(index) {
      this.valueData[index].isSelected = this.valueData[index].isSelected ? false : true;
    },
    leftToRight() {
      this.selectTopics = [...this.selectTopics, ...this.valueData?.filter(item => item.isSelected == true)];
      this.valueData = this.valueData?.filter(item => item.isSelected !== true)
    },
    leftToAll() {
      this.valueData.map((value) => {
        value.isSelected = true;
      })
      this.selectTopics = [...this.selectTopics, ...this.valueData?.filter(item => item.isSelected == true)];
      this.valueData = this.valueData?.filter(item => item.isSelected !== true)
    },
    moveButton(index) {
      this.selectTopics[index].isSelected = this.selectTopics[index].isSelected ? false : true;
    },
    rightToLeft() {
      this.valueData = [...this.valueData, ...this.selectTopics?.filter(item => item.isSelected == false)];
      this.selectTopics = this.selectTopics?.filter(item => item.isSelected !== false)
    },
    rightToAll() {
      this.selectTopics.map((value) => {
        value.isSelected = false;
      })
      this.valueData = [...this.valueData, ...this.selectTopics?.filter(item => item.isSelected == false)];
      this.selectTopics = this.selectTopics?.filter(item => item.isSelected !== false)
    },
    async getPatientSubGroup() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/patient-support`)
        .then((response) => {
          if (response?.data) {
            this.patientSupData = response.data;
            this.patientSupData.map((data) => {
              data.special = JSON.parse(data.special)
            })
          }
        })
        .catch(function (error) {
          return error
        });
    },
    async getPatientAboutGroup() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      this.patientIds = localStorage.getItem("patient-id")
      axios
        .get(`${BASE_API_URL}/patient-support/add/${this.patientIds}`)
        .then((response) => {
          if (response?.data) {
            this.patientAboutData = response.data;
            this.patientAboutData.map((data) => {
              this.addPatient.push(JSON.stringify(data));
              localStorage.setItem('addGroup', this.addPatient)
              data.special = JSON.parse(data.special)
            })
          }
        })
        .catch(function (error) {
          return error
        });
    },

    async add(payload) {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .post(`${BASE_API_URL}/patient-support`, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
            this.groupname = response.data.grouplongname;
            this.shortname = response.data.shortname;
            this.practicetopics = response.data.topicGroup;
            this.topicsdata = response.data.selectTopics;
            this.practiceareatopics = response.data.valueData;
            this.getPatientSubGroup();
          }
        })
        .catch(function (error) {
          return error
        })
    },
    async update(updatePayload, groupData) {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      await axios
        .put(`${BASE_API_URL}/patient-support/${groupData.id}/update`, updatePayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
            this.userPayload = response.data;
          }
        })
        .catch(function (error) {
          return error
        });
    },
    async getPatientSupportById(groupName) {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      await axios
        .get(`${BASE_API_URL}/patient-support/${groupName}/group-name`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
            this.subGroupValue = response.data;
          }
        })
        .catch(function (error) {
          return error
        })
    },
    async saveTopics() {
      const topic = []
      this.selectTopics.map((item) => {
        topic.push(item);
      })
      const practic = []
      this.valueData.map((item) => {
        practic.push(item);
      })
      let payload = {
        grouplongname: this.grouplongname,
        shortname: this.shortname,
        practicetopics: this.topicGroup.name,
        topicsdata: topic,
        practiceareatopics: practic
      };
      this.patientSupData?.map((patientData) => {
        if (patientData.groupname === this.grouplongname) {
          this.groupData = patientData
        }
      })
      if (Object.keys(this.groupData).length > 0) {
        if (this.groupData.groupname === this.grouplongname) {
          if (this.groupData.practicetopics === this.topicGroup.name) {
            this.update(payload, this.groupData)
          } else {
            this.add(payload)
          }
        } else {
          this.add(payload)
        }
      } else {
        if (this.groupData.groupname !== this.grouplongname) {
          this.add(payload)
        }
      }
    },
    async updateTopics() {
      const topic = []
      this.selectTopics.map((item) => {
        topic.push(item);
      })
      const practic = []
      this.valueData.map((item) => {
        practic.push(item);
      })
      let payload = {
        grouplongname: this.grouplongname,
        shortname: this.shortname,
        practicetopics: this.topicGroup.name,
        topicsdata: topic,
        practiceareatopics: practic
      };
      this.patientSupData?.map((patientData) => {
        if (patientData.groupname === this.grouplongname) {
          this.groupData = patientData
        }
      })
      if (Object.keys(this.groupData).length > 0) {
        if (this.groupData.groupname === this.grouplongname) {
          if (this.groupData.practicetopics === this.topicGroup.name) {
            this.update(payload, this.groupData)
          } else {
            this.add(payload)
          }
        } else {
          this.add(payload)
        }
      } else {
        if (this.groupData.groupname !== this.grouplongname) {
          this.add(payload)
        }
      }
    },
    handleInputEditState(value, index) {
      this.patientUpdateData=value
      this.updateValueId = value.id;
      this.editbutton = true;
      this.patientSupport = true;
      this.grouplongname = value?.grouplongname;
      this.shortname = value?.shortname;
      this.specialities = value?.specialities;
      this.status = value?.status;
      this.specialitiesValues = this.specialities;
      this.groupDataValue = value;
      this.topicGroup = this.specialities[0];
      this.special.push(this.specialities[0])
      this.groupSelect(this.specialities[0])
      let previousData = [...this.updateValue]
      previousData[index].isEditable = true;
      // previousData[index].check = true;
      this.updateValue = previousData;
    },
    getGroup() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/patient-support/add`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async (response) => {
          this.updateValue=[]
          if (response?.data) {
            this.groupUpdate = response.data;
            await this.groupUpdate.map(async (data) => {
              data.specialities = [...new Set(data.specialities)]
              this.updateValue.push({
                id: data.id,
                grouplongname: data.groupname,
                shortname: data.shortname,
                specialities: data.specialities,
                status: data.status
              })
            })
          }
        })
        .catch(function (error) {
         return error
        });
    },
    async savingGroup() {
      // this.getGroup()
      // this.patientsupAdd()

      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      this.patientIdValue = localStorage.getItem("patient-id")
      let updatesGroupPayload = {
        groupname: this.grouplongname,
        shortname: this.shortname,
        specialities: this.specialities,
        status: this.status,
        hcpPatient: this.getData,
        hcps_id: this.getData,
        users_id: this.userData
      }
      await axios
        .put(`${BASE_API_URL}/patient-support/add/${this.patientIdValue}/update`, updatesGroupPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
            this.userPayloadUpdate = response.data;
            this.groupname = this.userPayloadUpdate.grouplongname;
            this.shortname = this.userPayloadUpdate.shortname;
            this.specialities = this.userPayloadUpdate.specialities;
            this.status = this.userPayloadUpdate.status;
            this.special = this.specialities
            this.topicGroup = this.userPayloadUpdate.specialities;
            this.$swal("Added");
            this.patientSupport=false
            this.getGroup();
          }
        })
        .catch(function (error) {
          return error
        });
    },
    async updateSavingGroup() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      this.patientIdValue = localStorage.getItem("patient-id")
      let updatesGroupPayload = {
        groupname: this.grouplongname,
        shortname: this.shortname,
        specialities: this.specialities,
        status: this.status,
        hcpPatient: this.getData,
        hcps_id: this.getData,
        users_id: this.userData
      }
      await axios
        .put(`${BASE_API_URL}/patient-support/add/${this.updateValueId}/update`, updatesGroupPayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
            this.userPayloadUpdate = response.data;
            this.$swal("Updated");
            this.getPatientAboutGroup();
            this.getGroup();
            this.patientSupport=false
          }
        })
        .catch(function (error) {
          return error
        });
    }
  }
}

</script>
<style>
.multiselect--active
.multiselect__content-wrapper {
  display: block !important;
}
button.arrow-button {
  margin: 2px;
  background-color: white;
  border-left-color: currentcolor;
  border-right-color: currentColor;
  border-top-color: currentcolor;
  border-bottom-color: currentcolor;
  width: 32px;
  height: 30px;
}

button.arrow-left-button {
  margin-left: 35px;
  margin-top: 10px;
}

.taskmanager-title1 {
  background: white;
  padding: 3px;
  margin-top: -41px;
  width: 155px;
}

.taskmanager-title2 {
  background: white;
  padding: 3px;
  margin-top: -41px;
  width: 180px;
}

.form-control.checkselect {
  height: 100%;
}

button.btn.btn-blue-color.selected {
  width: 50px;
  margin: 3px;
  white-space: nowrap;
  font-size: 10px;
  padding: 6px;
  overflow: hidden;
  color: black;
  z-index: 1;
}

button.btn.btn-blue-color.selected:hover {
  width: 50px; /* Change the width when hovering, if needed */
  margin: 3px;
  font-size: 10px;
  overflow: visible;
  padding: 6px;
  z-index: 1;
}

button.btn.btn-outline-secondary.selected {
  width: 50px;
  margin: 3px;
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  padding: 6px;
  color: black;
  z-index: 1;
}

button.btn.btn-outline-secondary.selected:hover {
  width: 50px;
  margin: 3px;
  font-size: 10px;
  overflow: visible;
  padding: 6px;
  z-index: 1;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  opacity: 1;
  width: 50px;
  margin: 3px;
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  padding: 6px;
  color: black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 120%; /* Show tooltip on top */
  left: 50%;
  transform: translateX(-50%);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.practice-topic
{
  max-width: 50px;
  overflow: hidden;
}
.practice-topic:hover {
  overflow: hidden;
  text-overflow: ellipsis;
}

.practice-topic-move
{
  max-width: 50px;
  overflow: hidden;
}
.practice-topic-move:hover {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>