<template>
    <div class="row mt-3">
      <div class="col-7">
        <label class="text-color">Resource Type</label>
        <select class="form-control" id="inputGroupSelect01">
            <option value="1">Select</option>
        </select>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-7">
        <label class="text-color">Condition/ Drug/ Procedure/ Diagnosis Test</label>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-7">
        <label class="text-color">Name</label>
        <input type="text" id="name" name="name" class="form-control"/>
      </div>
    </div>
    <div class="row">
      <label for="exampleFormControlInput1" class="form-label">URL</label>
      <div class="col-7 url-textbox" v-for="area in local_area" :key="area.index">
        <input
          id="consult_url"
          v-model="area.one"
          name="consult_url"
          class="form-control"
          type="text"
          aria-label="Url"
        />
      </div>
      <div class="col-2 btn">
        <button
          type="button"
          @click="addarea()"
          id="btn_homeconsult_add"
          class="btn btn_add div-btn"
        >
          <font-awesome-icon :icon="['fas', 'plus-circle']" />
        </button>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-7">
        <label class="text-color">Related RxIx Topics</label>
        <textarea id="ctext" name="ctext" class="form-control" rows="4"></textarea>
      </div>
    </div>
    <div class="row mt-3">
    <div class="col-12" align="center">
      <button type="button" class="btn btn-blue-color text-white">Submit</button>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      local_area: [
        {
          one: "",
          two: "",
          three: "",
        },
      ],
    };
  },
  methods: {
    addarea() {
      this.local_area.push({
        one: "",
        two: "",
        three: "",
      });
    },
  },
};
</script>
<style>
label.form-label {
    color: #00979e;
  width: 100% !important;
    margin-top: 15px;
}
    .btn.btn_add.div-btn {
    width: 55px;
    height: 50px;
    font-size: 30px;
}
.col-7.url-textbox{
  margin-bottom: 15px;
}
</style>