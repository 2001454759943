<template>
  <div v-if="!openSign">
    <div class="row my-2">
      <div class="col-5">
        <label class="text-color">HCP Types</label>
      </div>
      <div class="col-5">
        <label class="text-color">Practice Area</label>
      </div>
      <!-- <div class='col-2'>
        <label class="text-color">#disorder</label>
      </div>
      <div class='col-2'>
        <label class="text-color">#resources</label>
      </div> -->
      <div class='col-1'>
        <label class="text-color">A/D</label>
      </div>
    </div>
    <div class="row my-2" v-for="(value, index ) in hcpTypesData" :key="index">
      <div class="col-5 mt-2">
        <input type="text" id="name" name="name" autocomplete="off" :disabled="!value.isEditable" :value="value.hcpTypes"
          class="form-control" />
      </div>
      <div class="col-5 mt-2">
        <input type="text" id="name" name="name" autocomplete="off" :disabled="!value.isEditable"
          :value="value.practiceArea" class="form-control" />
      </div>
      <!-- <div class="col-2 mt-2">
        <input type="text" id="number" name="number" :disabled="!value.isEditable" :value="value.disorder.length"
          class="form-control" />
      </div>
      <div class="col-2 mt-2">
        <input type="text" id="number" name="number" :disabled="!value.isEditable" class="form-control" />
      </div> -->
      <div class="col-1 mt-2">
        <input type="checkbox" v-model="value.check" @click="enableOn($event, index)" class="me-2" name="checkbox"
          :disabled="!value.isEditable" style="margin-top: 13px;" />
      </div>
      <div class="col-1 mt-2">
        <button type="button" @click.prevent="handleInputEditState(value, index)" style="font-size: 20px"
          id="btn_schedule_add" class="btn btn_edit">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </button>
      </div>
      <!-- <div class="col-1 mt-2">
        <button type="button" @click.prevent="softDeleteState(value, index)" style="font-size: 20px" id="btn_schedule_add"
          class="btn btn_save">
          <img src="@/assets/images/trash.png" class="btn-save" alt="">
        </button>
      </div> -->
    </div>
    <div class="row">
      <div class="col-10 mt-3">
        <hr class="hr-border" />
      </div>
      <div class="col-2">
        <button type="button" @click="showInputForm()" style="font-size: 30px" id="btn_schedule_add" class="btn btn_add">
          <font-awesome-icon :icon="['fas', 'plus-circle']" />
        </button>
      </div>
    </div>
    <div class="" v-if="practiceAreaForm == true">
      <div class="row mt-3">
        <div class="col-7">
          <label class="text-color">HCP Types</label>
          <select class="form-control" id="inputGroupSelect" v-model="hcpTypes">
            <option :value=hcptypes.hcpType v-for="hcptypes in typeHCP" :key="hcptypes">{{hcptypes.hcpType}}</option>
          </select>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-7">
          <label class="text-color">Practice Area</label>
          <input type="text" id="name" name="name" autocomplete="off" class="form-control" v-model="area" />
        </div>
      </div>
      <!-- <div class="row mt-3">
        <div class="col-12">
          <label class="text-color">Disorder</label>
          <VueMultiselect v-model="disorder" :options="options" :multiple="true" :close-on-select="true"
            placeholder="Pick some" label="disorder" track-by="id" />
        </div>
      </div> -->
      <div class="row mt-3">
        <div class="col-7">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="resources" id="resources" v-model="check" />
            <label class="form-check-label text-color" for="activateORdeactivate">Activate / Deactivate</label>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-7" align="right">
          <button type="button" @click.prevent="editbutton ? getUpdateValues() : getFormValues()"
            class="btn btn-blue-color text-white">
            {{ this.editbutton ? "Update" : "Save" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import VueMultiselect from "vue-multiselect";
import axios from "axios";

export default {
  // components: { VueMultiselect },
  data() {
    return {
      practiceAreaForm: false,
      hcpTypes: "",
      area: "",
      practice: "",
      resources: false,
      options: [],
      disorder: null,
      hcpTypesData: [],
      check: false,
      checkboxValue: {},
      getData:[],
      deletehcpTypesData:[],
      editbutton: false,
      typeHCP:[]
    };
  },
  methods: {
    getTypeHcp(){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
      .get(`${BASE_API_URL}/type-hcp`)
      .then((response)=>{
        if(response.data){
          this.typeHCP=response.data
        }
      })
    },
    showInputForm() {
      this.practiceAreaForm = !this.practiceAreaForm
    },
    capitalize(s) {
      return s[0].toUpperCase() + s.slice(1);
    },
    getHcps() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let DoctorUserId = localStorage.getItem('id')
      this.DoctorId = JSON.parse(DoctorUserId)
      axios
        .get(`${BASE_API_URL}/hcps/${DoctorUserId}/one`)
        .then((response) => {
          if(response?.data){
            this.getData = response.data
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
    getPracticeAreabyId() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/hcp-types/all`)
        .then((response) => {
          if (response?.data) {
            this.hcpTypesData = response.data;
            response.data?.map((data) => {
              this.practiceAddArea = data.practiceArea;
            })
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
    getFormValues() {
      this.hcpTypes=this.hcpTypes.toLocaleLowerCase()
      // localStorage.removeItem('practice-area');
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let disorder = this.disorder?.length
        ? this.disorder?.map((topic) => topic)
        : [];
      let payload = {
        practiceArea: this.capitalize(this.area),
        disorder: disorder,
        hcpTypes: this.hcpTypes,
        hcps_id: this.getData,
        check:this.check
      };
      // if (this.area && this.resources == true) {
      axios
        .post(`${BASE_API_URL}/hcp-types`, payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
            this.area = "";
            this.disorder = [];
            this.hcpTypes = "";
            this.resources = false;
            this.hcps_id = response.data.hcps_id;
            this.check = response.data.check;
            this.$swal("Added");
            this.getPracticeArea()
            localStorage.setItem('practice-area', payload.practiceArea)
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
    getUpdateValues() {
      this.hcpTypes=this.hcpTypes.toLocaleLowerCase()
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      let disorder = this.disorder?.length
        ? this.disorder?.map((topic) => topic)
        : [];
      let updatePayload = {
        practiceArea: this.capitalize(this.area),
        disorder: disorder,
        hcpTypes: this.hcpTypes,
        hcps_id: this.getData,
        check: this.check
      }
      axios
        .put(`${BASE_API_URL}/hcp-types/${this.editValueId}/update`, updatePayload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response?.data) {
            this.$swal("Updated");
            this.practiceAreaForm=false
            this.getPracticeArea()
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
      // }
    },
    getTopics() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        // .get(`${BASE_API_URL}/library-disorders`)
        .get(`${BASE_API_URL}/topics`)
        .then((response) => {
          if (response?.data) {
            this.options = response.data;
          }
        })
    },
    getPracticeArea() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      axios
        .get(`${BASE_API_URL}/hcp-types/all`)
        .then((response) => {
          if (response?.data) {
            this.hcpTypesData = response.data;
            response.data?.map((data)=>{
              this.practiceAddArea = data.practiceArea;
            })
          }
        })
        .catch(function (error) {
          console.log(error.response);
        });
    },
    handleInputEditState(value, index) {
      if(value.check === true){
        this.check = true;
      }
      else{
        this.check = false;
      }
      this.editValueId = value.id
      this.editValuePracticeArea = value.practiceArea
      this.editValueDisorder = value.disorder
      this.editValueHcpTypes = value.hcpTypes
      this.editValueCheck = value.check
      this.editbutton = true
      this.practiceAreaForm = true
      let previousData = [...this.hcpTypesData]
      previousData[index].isEditable = true;
      // previousData[index].check = true;
      this.hcpTypesData = previousData;
      this.area = previousData[index].practiceArea;
      this.hcpTypes = previousData[index].hcpTypes;
      this.disorder = previousData[index].disorder;
      this.resources = previousData[index].check;
    },

    // update practice area api 

    // updateState(value) {
    //   const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    //   let payload = value;
    //   axios
    //     .put(`${BASE_API_URL}/hcp-types/${value.id}/update`, payload, {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     })
    //     .then((response) => {
    //       this.getPracticeArea(value.id);
    //       if (response?.data) {
    //         this.hcpTypesData = response.data;
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error.response);
    //     });
    // },

    // delete & soft delete api 
    
    // deleteState(value) {
    //   const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    //   axios
    //     .delete(`${BASE_API_URL}/hcp-types/${value.id}`, {
    //       headers: {
    //         "Content-Type": "application/json",
    //       },
    //     })
    //     .then((response) => {
    //       if (response?.data) {
    //         this.hcpTypesData = response.data;
    //         this.$swal("Deleted");
    //       }
    //     })
    //     .catch(function (error) {
    //       console.log(error.response);
    //     });
    // },
  //   softDeleteState(value, index) {
  //   const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
  //   axios
  //     .patch(`${BASE_API_URL}/hcp-types/${value.id}/soft-delete`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((response) => {
  //       if (response?.data) {
  //         this.hcpTypesData.splice(index, 1);
  //         this.$swal("Deleted");
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error.response);
  //     });
  // },
    enableOn(event, index) {
      if (index) {
        this.hcpTypesData[index].check = event
      }
    }
  },
  created: function () {
    this.getPracticeAreabyId();
    this.getPracticeArea()
    this.getTopics();
    this.getHcps();
    this.getTypeHcp();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
input#name {
  text-transform: capitalize;
}

img.btn-save {
  width: auto;
  height: 20px;
}
</style>